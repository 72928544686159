import { createAction } from "@reduxjs/toolkit";
import { CLEAR_COUNTRY, GET_COUNTRY, GET_STATE, SET_PROJECT, CLEAR_PROJECT } from "../constants";

export const getCountry = createAction(GET_COUNTRY);
export const getState = createAction(GET_STATE);
export const clearCountry = createAction(CLEAR_COUNTRY);
export const setProject = createAction(SET_PROJECT);
export const clearProject = createAction(CLEAR_PROJECT);

export default {
  getCountry,
  getState,
  setProject,
  clearProject
};
