import { createReducer } from "@reduxjs/toolkit";
import { CLEAR_COUNTRY, GET_COUNTRY, GET_STATE, SET_PROJECT, CLEAR_PROJECT } from "../constants";

const initialState = {
  country: "",
  state: [],
  project: ""
};

export default createReducer(initialState, {
  [GET_COUNTRY]: (state, { payload }) => ({
    ...state,
    country: payload
  }),

  [GET_STATE]: (state, { payload }) => ({
    ...state,
    state: payload
  }),

  [SET_PROJECT]: (state, { payload }) => ({
    ...state,
    project: payload
  }),

  [CLEAR_COUNTRY]: (state) => ({
    ...state,
    country: ""
  }),

  [CLEAR_PROJECT]: (state) => ({
    ...state,
    project: ""
  }),
});
