import { CButton, CModalHeader } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setProject } from '../redux/actions/country';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { changeProject } from '../utils/axios';
import { SettingsModalBoldText } from '../views/settings/SettingsModals';
import { StyledModal, StyledModalBody } from './Modals';
import styled from 'styled-components';
import { Fonts, Palette } from './utils';
import {Projects} from '../views/users/constant';

export const ProjectsData = {
  GRATA: { 
    nativeName: 'GRATA',
    icon: 'https://storage.googleapis.com/grata-media-dev/10399-88cc1b0c-ec1a-4257-ad31-5c0f6696ed8c.png'
  },
  ZMOD: { 
    nativeName: 'ZMOD',
    icon: 'https://storage.googleapis.com/grata-media-dev/zmod-text-icon-2.png'
  }, 
};

const EditProject = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const { project } = useSelector((state) => state.country);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangeProject = (selectedProject) => {
    if (selectedProject != project) {
      dispatch(setProject(selectedProject));
      localStorage.setItem('project', selectedProject);
  
      changeProject(selectedProject);
      history.go(0);
    }
    onClose();
  };

  const onClose = () => {
    dispatch(changeOpenedModal({ modal: '' }));
  };

  return (
    <StyledModal
      show={modal === 'ModalEditProject' ? true : false}
      onClose={onClose}
      closeOnBackdrop={false}>
      <CModalHeader closeButton={false} className="flex justify-content-center">
        <SettingsModalBoldText fontSize="28px">
          {t('Please select Project')}
        </SettingsModalBoldText>
      </CModalHeader>
      <StyledModalBody>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginBottom: '20px'
          }}>
          {Object.keys(ProjectsData).map((key) => (
            <StyledSelectButton
              size="sm"
              key={key}
              onClick={() => handleChangeProject(key)}
              selected={project === key}
              disabled={!Object.values(Projects).includes(key)}>
              <StyledIcon 
                src={ProjectsData[key].icon}
              />
              {ProjectsData[key].nativeName}
            </StyledSelectButton>
          ))}
        </div>
      </StyledModalBody>
    </StyledModal>
  );
};

const StyledSelectButton = styled(CButton)`
  margin-right: 0px;
  margin-left: 1;
  font-size: inherit;
  border: none;
  color: black;
  box-shadow: none !important;
  width: 160px;

  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  :hover {
    color: ${Palette.BLACK};
    background-color: rgba(117, 117, 117, 0.2);
  }

  background: ${({ selected }) => (selected ? 'rgba(117, 117, 117, 0.2)' : 'none')};
`;

const StyledIcon = styled.img`
  width: 134px;
  height: 134px;
  border-radius: 10px;
`;

export default EditProject;
