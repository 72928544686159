import { CButton, CModalHeader } from '@coreui/react';
import { CA, US } from 'country-flag-icons/react/3x2';
import i18n from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCountry } from '../redux/actions/country';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { changeDatacenter } from '../utils/axios';
import { SettingsModalBoldText } from '../views/settings/SettingsModals';
import { StyledModal, StyledModalBody } from './Modals';
import styled from 'styled-components';
import { Fonts, Palette } from './utils';

export const lngs = {
  us: { nativeName: 'United States' },
  ca: { nativeName: 'Canada' }
};

const EditDataCenter = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const { country } = useSelector((state) => state.country);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangeDataCenter = (lng) => {
    if (lng != country) {
      dispatch(getCountry(lng));
      localStorage.setItem('data_center', lng);
  
      changeDatacenter(lng);
      history.go(0);
    }

    onClose();
  };

  const onClose = () => {
    dispatch(changeOpenedModal({ modal: '' }));
  };

  return (
    <StyledModal
      show={modal === 'ModalEditDataCenter' ? true : false}
      onClose={onClose}
      closeOnBackdrop={false}>
      <CModalHeader closeButton={false} className="flex justify-content-center">
        <SettingsModalBoldText fontSize="28px">
          {t('Please select data center')}
        </SettingsModalBoldText>
      </CModalHeader>
      <StyledModalBody>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginBottom: '20px'
          }}>
          {Object.keys(lngs).map((lng) => (
            <StyledSelectButton
              size="sm"
              key={lng}
              onClick={() => handleChangeDataCenter(lng)}
              selected={country === lng}
              disabled={i18n.resolvedLanguage === lng}>
              {lngs[lng].nativeName === 'United States' ? (
                <US title="United States" fill="yellow" />
              ) : (
                <CA title="Canada" />
              )}

              {lngs[lng].nativeName}
            </StyledSelectButton>
          ))}
        </div>
      </StyledModalBody>
    </StyledModal>
  );
};

const StyledSelectButton = styled(CButton)`
  margin-right: 20px;
  margin-left: 1;
  font-size: inherit;
  border: none;
  color: black;
  box-shadow: none !important;
  width: 150px;

  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  :hover {
    color: ${Palette.BLACK};
    background-color: rgba(117, 117, 117, 0.2);
  }

  background: ${({ selected }) => (selected ? 'rgba(117, 117, 117, 0.2)' : 'none')};
`;

export default EditDataCenter;
