import axios from "axios";
import i18n from "i18next";

const clearAndReload = () => {
  localStorage.clear();
  window.location.reload();
}

export const getLanguage = () => {
  return (
    i18n.language ||
    (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
    "en"
  );
};
export const baseURL = process.env.REACT_APP_BASE_URL;
// export const gratafyURL = process.env.REACT_APP_GRATAFY_URL;

const API = axios.create({
  baseURL
});

API.defaults.headers.common["locale"] = getLanguage();

export const changeApiLocale = () => {
  API.defaults.headers.common["locale"] = getLanguage();
};

export const changeDatacenter = (loc) => {
  API.defaults.headers.common["org-location"] = loc;
};

API.defaults.headers.common["org-location"] = window.localStorage.data_center;

export const changeProject = (project) => {
  API.defaults.headers.common["project"] = project;
};

API.defaults.headers.common["project"] = window.localStorage.project;

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const lastLoginTime = localStorage.getItem("last_login");
    const isSessionActive = lastLoginTime && ( 
      parseInt(lastLoginTime, 10) + 1000 * 60 * 60 * 12 > new Date().getTime() )
    if (
      error.response.data.code === 401 &&
      (error.response.data.message === "Jwt is expired" ||
        error.response.data.message ===
          "Jwt is not in the form of Header.Payload.Signature with two dots and 3 sections") &&
      !originalRequest._retry
    ) {
      if ( !isSessionActive ) {
        clearAndReload();
        localStorage.setItem('session_expired', true);
        return;
      }
      try {
        let res = await refreshAccessToken();
        if (res.status === 200 || res.status === 201) {
          localStorage.setItem("idToken", res.data.idToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          localStorage.setItem("last_login", new Date().getTime());
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${res.data.idToken}`;
          return axios(originalRequest);
        } else {
          clearAndReload();
          return Promise.reject(error);
        }
      } catch (error) {
        clearAndReload();
        return Promise.reject(error);
      }
    } else if (
      (parseInt(error.response.data.code) === 403 &&
        error.response.data.message === "not logged in") ||
      (parseInt(error.response.data.code) === 400 &&
        error.response.data.message ===
          "failed to refresh token. TOKEN_EXPIRED") ||
      (parseInt(error.response.data.code) === 401 ) ||
      (parseInt(error.response.data.code) === 400 &&
        error.response.data.message.includes(
          "failed to refresh token. firebase returned http status 400"
        ))
    ) {
      clearAndReload();
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const headers = {
    "Content-Type": "application/form-x-encoded",
    "Access-Control-Allow-Origin": "*"
  };
  const data = {
    refreshToken
  };

  if (refreshToken == "" || !refreshToken) return;

  const response = await API.post(
    `/auth/refresh?key=${process.env.REACT_APP_TOKEN_API_KEY}`,
    data,
    headers
  );
  return response;
};

export default API;
