import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeActiveModal, openModal } from '../../../helper/modals';
import {
  addUserTypeTitle,
  ToggleItem,
  ToggleItemComponentContainer,
  ToggleItemDescription,
  ToggleItemTextContainer
} from '../ManageUserComponents';
import * as Yup from 'yup';
import { updateUserTypeData } from '../../../redux/actions/addUserType';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ErrorText } from '../../../components/utils/AvatarCropper';
import { isYardiOrganization, USER_ROLE, USER_ROLE_OPTIONS } from '../../users/constant';
import { Radio } from 'antd';
import styled from 'styled-components';
import { closeAddUserTypeModal } from '../ManageUserComponents';
import { InputSeparator } from '../../settings/SettingsModals';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';

const AddNewUserTypeModalStep1 = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const addUserType = useSelector((state) => state.addUserType);
  const { roleId } = useSelector((state) => state.auth);
  const { organization } = useSelector((state) => state.Organization);
  const dispatch = useDispatch();
  const schema = Yup.object().shape({
    role: Yup.number()
      .required(t('User Role is required'))
      .oneOf(
        USER_ROLE_OPTIONS.map((userRole) => userRole.id),
        t('User Role is required')
      )
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      role: addUserType.role
    }
  });

  const onSubmit = (data) => {
    dispatch(
      updateUserTypeData({
        role: data.role
      })
    );
    openModal('AddNewUserTypeModalStep2');
  };

  const permissionRole = useMemo(() => {
    if (roleId === USER_ROLE.VENDOR.id) {
      return [USER_ROLE.USER, USER_ROLE.VENDOR];
    }

    return USER_ROLE_OPTIONS.filter((role) => role.id <= roleId || role === USER_ROLE.VENDOR);
  }, [organization]);

  useEffect(() => {
    if (roleId != USER_ROLE.SUPER_ADMIN.id) {
      dispatch(
        updateUserTypeData({
          organization: {
            organizationId: organization.id,
            name: organization.name
          }
        })
      );
    }
  }, []);

  return (
    <StyledModal
      show={modal === 'AddNewUserTypeModalStep1'}
      onClose={() => closeAddUserTypeModal(addUserType.editingID)}>
      <ModalHeader
        title={addUserTypeTitle(addUserType, false)}
        onCloseCallback={() => closeAddUserTypeModal(addUserType.editingID)}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledModalBody>
          <Controller
            defaultValue={addUserType.role}
            control={control}
            name="role"
            render={({ field: { onChange, value, name } }) => (
              <>
                <StyledRadioGroup
                  size="large"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}>
                  {permissionRole.map((userRole) => {
                    return (
                      <InputSeparator>
                        <ToggleItem key={userRole.value}>
                          <ToggleItemTextContainer>
                            <ToggleItemDescription>{t(userRole.value)}</ToggleItemDescription>
                          </ToggleItemTextContainer>
                          <ToggleItemComponentContainer>
                            <StyledRadio value={userRole.id} id={userRole.id} />
                          </ToggleItemComponentContainer>
                        </ToggleItem>
                      </InputSeparator>
                    );
                  })}
                </StyledRadioGroup>
                <ErrorMessage
                  errors={errors}
                  name={name}
                  render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
              </>
            )}
          />
          <ModalButtons
            leftButtonText={t('Cancel')}
            leftButtonClick={closeActiveModal}
            rightButtonText={t('Next')}
          />
        </StyledModalBody>
      </form>
    </StyledModal>
  );
};

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    width: 30px;
    height: 30px;
    border-color: black;
    border-width: 3px;

    :hover {
      border-color: black;
    }
  }

  .ant-radio-inner::after {
    :hover {
      border: 1px solid black;
    }
    background-color: black;
  }

  .ant-radio-checked::after {
    :hover {
      border: 1px solid black;
    }
    transform: scale(0.6);
    border: 1px solid black;
    box-shadow: 0 0 0 2px black;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: black !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: black;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: black;
  }
`;

export default AddNewUserTypeModalStep1;
