import { createReducer } from "@reduxjs/toolkit";
import {
  LOG_IN,
  LOG_OUT,
  SET_IS_LOGGED_IN,
  SET_ROLE_ID,
  CHANGE_LOGIN_PAGE,
  VERIFY_ACCOUNT,
  CONFIRM_OTP,
  FORGOT_PASSWORD,
  SET_LOGIN_TRUE,
  SET_CONFIG,
  CLEAR_LOGIN_ERROR,
  SET_LOGIN_FALSE
} from '../constants';
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../utils/status";
import {NEW_NOTIFICATION_KEY, USER_ID_KEY} from "../../containers/constants";
import {Projects} from "../../views/users/constant";

const initialState = {
  isLoggedIn: null,
  orgId: null,
  roleId: null,
  building_id: null,
  user_id: null,
  page: "0",
  status: "",
  error: "",
  config: {}
};

export default createReducer(initialState, {
  [CLEAR_LOGIN_ERROR]: (state) => {
    return {
      ...state,
      error: ""
    };
  },
  [SET_LOGIN_TRUE]: (state, { payload }) => {
    return {
      ...state,
      isLoggedIn: true
    };
  },
  [SET_LOGIN_FALSE]: (state) => {
    return {
      ...state,
      isLoggedIn: false
    };
  },
  [SET_CONFIG]: (state, { payload }) => {
    const tempPayload = {
      ...JSON.parse(payload)
    };

    return {
      ...state,
      config: tempPayload
    };
  },

  [requestSuccess(FORGOT_PASSWORD)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(FORGOT_PASSWORD),
    page: "2",
    error: ""
  }),

  [requestFail(FORGOT_PASSWORD)]: (state, { payload }) => ({
    ...state,
    status: requestFail(FORGOT_PASSWORD),
    error: payload.data.message
  }),

  [requestSuccess(CONFIRM_OTP)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(CONFIRM_OTP),
    page: "2",
    error: ""
  }),

  [requestFail(CONFIRM_OTP)]: (state, { payload }) => ({
    ...state,
    status: requestFail(CONFIRM_OTP),
    error: payload.data.message
  }),

  [requestSuccess(LOG_IN)]: (state, { payload }) => {
    const storedUserId = localStorage.getItem(USER_ID_KEY);
    if(Number(payload.user_id) !== Number(storedUserId)) localStorage.removeItem(NEW_NOTIFICATION_KEY);
    localStorage.setItem(USER_ID_KEY, payload.user_id);

    localStorage.setItem("idToken", payload.idToken);
    localStorage.setItem("refreshToken", payload.refreshToken);
    localStorage.setItem("orgId", payload.orgId);
    localStorage.setItem("roleId", payload.roleId);
    localStorage.setItem("firstname", payload.firstname);
    localStorage.setItem("lastname", payload.lastname);
    localStorage.setItem("building_id", payload.building_id);
    localStorage.setItem("type_id", payload.type_id);
    localStorage.setItem("error_code", payload.error_code);
    localStorage.setItem("avatar", payload.avatar);
    localStorage.setItem(
      "config",
      JSON.stringify({
        ...payload.config
      })
    );
    localStorage.setItem("last_login", new Date().getTime());
    // remove default value when zmod BE completed
    localStorage.setItem("project", payload?.project || Projects.GRATA);

    return {
      ...state,
      error: "",
      config: payload.config,
      isLoggedIn: true,
      orgId: payload.orgId,
      roleId: payload.roleId,
      building_id: payload.building_id,
      user_id: payload.user_id
    };
  },

  [requestPending(LOG_IN)]: (state, { payload }) => ({
    ...state,
    status: requestPending(LOG_IN)
  }),

  [requestFail(LOG_IN)]: (state, { payload }) => {
    return {
      ...state,
      error: payload.data.message,
      status: requestFail(LOG_IN)
    };
  },

  [CHANGE_LOGIN_PAGE]: (state, { payload }) => {
    return {
      ...state,
      page: payload.page
    };
  },

  [VERIFY_ACCOUNT]: (state, { payload }) => {
    localStorage.setItem("idToken", payload.idToken);
    localStorage.setItem("refreshToken", payload.refreshToken);
    localStorage.setItem("orgId", payload.orgId);
    localStorage.setItem("roleId", payload.roleId);
    localStorage.setItem("firstname", payload.firstname);
    localStorage.setItem("lastname", payload.lastname);
    localStorage.setItem("building_id", payload.building_id);
    localStorage.setItem("user_id", payload.user_id);
    localStorage.setItem("type_id", payload.type_id);
    localStorage.setItem("error_code", payload.error_code);
    localStorage.setItem("user", payload.email);
    localStorage.setItem("avatar", payload.avatar);
    localStorage.setItem(
      "config",
      JSON.stringify({
        ...payload.config
      })
    );
    // remove default value when zmod BE completed
    localStorage.setItem("project", payload?.project || Projects.GRATA);
    return {
      ...state,
      page: payload.page,
      config: payload.config,
      error: "",
      status: requestSuccess(LOG_IN)
    };
  },

  [SET_IS_LOGGED_IN]: (state, { payload }) => {
    return {
      ...state,

      isLoggedIn: payload.isLoggedIn,
      roleId: payload.roleId,
      building_id: payload.building_id,
      orgId: payload.orgId
    };
  },

  [SET_ROLE_ID]: (state, { payload }) => ({
    ...state,
    roleId: payload.roleId,
    building_id: payload.building_id,
    orgId: payload.orgId,
    user_id: payload.user_id
  }),

  [requestSuccess(LOG_OUT)]: (state) => {
    return {
      ...state,
      isLoggedIn: false,
      status: requestSuccess(LOG_OUT),
      error: ""
    };
  },

  [requestPending(LOG_OUT)]: (state) => {
    return {
      ...state,
      status: requestPending(LOG_OUT)
    };
  },

  [requestFail(LOG_OUT)]: (state) => {
    return {
      ...state,
      isLoggedIn: true,
      status: requestFail(LOG_OUT)
    };
  }
});
